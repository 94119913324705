<template>
    <div class="setting-plan">
        <div class="programme">
            <div class="programme_title_top">
                患者来源
            </div>
            <div v-for="(item,index) in barList" :key="index"  class="programme_title" :class="{programme_action:count == index}"  @click="sidebarClick(index,item)">
                <span>{{item.name}}</span>
            </div>

        </div>
        <Table ref="table"
               class="programme-right"
               :searchTime="false"
               :add="true"
               @add="add()"
               :tableTitle="tableTitle"
               :searchBtn="false"
               :form="form"
               :back="back"
               :deleteUrl="deleteUrl"
               :searchUrl="url">
        </Table>
        <el-dialog class="new-dialog" title="添加患者来源" :visible.sync="dialogFormVisible" width="30vw">
            <el-form :model="addForm">
                <el-form-item label="选择部门" label-width="100px">
                    <el-radio-group v-model="addForm.department_id">
                        <el-radio
                            v-for="(item) in barList"
                            :key="item.id"
                            :value ="item.id"
                            :label = "item.id"
                        >
                            {{item.name}}
                        </el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="患者来源名称" label-width="100px"  >
                    <el-input v-model="addForm.name" autocomplete="off" placeholder="请输入患者来源名称"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false" round style="background-color: #F6B05D; border-color: #F6B05D;color: #FFFFFF;">取 消</el-button>
                <el-button type="primary" @click="onAdd" round style="background-color: #7DB167; border-color: #7DB167;">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import Table from '../components/Table';

    export default {
        name       : "SettingWard",
        components : {
            Table
        },
        data() {
            return {
                count:0,
                tableTitle : [
                    {
                        name  : '编号',
                        value : 'num',
                        width : 200
                    },
                    {
                        name  : '患者来源',
                        value : 'name',
                        width : 500
                    },
                    {
                        name  : '添加时间',
                        value : 'create_at',
                        width : 400
                    },
                ],
                form       : {
                    id: ''
                },
                addForm: {
                    name: '',
                    department_id:''
                },
                back:{
                    text:'返回系统页'
                },
                url        : 'api/district/index',
                deleteUrl  : 'api/district/delete',
                tableData  : [],
                barList:[],
                dialogFormVisible: false
            };
        },
        computed   : {},
        watch      : {},
        async mounted() {
            await this.getWard();
            this.form.id = this.barList[this.count].id
        },
        methods    : {
            /**
             * 侧边栏点击
             * */
            sidebarClick(index,item) {
                this.count = index
                this.form.id = this.barList[this.count].id
                this.$refs.table.onRefresh()
            },
            add() {
                this.dialogFormVisible = true
            },
            async getWard() {
                await this.$axios.post('api/district/department').then(res => {
                    this.barList = res.data.data
                })
            },
            async onAdd(){
                if(this.addForm.department_id == '') {
                    this.$message({
                        type: 'info',
                        message: '请选择部门'
                    });
                    return;
                }
                if(this.addForm.name == '') {
                    this.$message({
                        type: 'info',
                        message: '请输入病区名称'
                    });
                    return;
                }
                await this.$axios.post('api/district/add',this.$qs.stringify( this.addForm)).then(res => {
                    if(res.data.code == 1){
                        this.$refs.table.onSubmit()
                        this.addForm.name = '';
                        this.addForm.department_id = '';
                        this.dialogFormVisible = false
                    }
                })
            },
        },
    }
</script>

<style scoped lang="scss">
::v-deep {
    .el-dialog__header {
        text-align: left;
        border-bottom: 1px solid #E8E8E8;
        font-size: 28px;
        font-weight: 600;
        color: #333333;
        line-height: 40px;
        padding-left: 50px;
    }
}
.setting-plan {
    display: flex;
}
    .el-form{
        width: 500px;
    }
    .programme {
        width: 231px;
        height: 100%;
        background: #FFFFFF;
        padding: 10px;
        border-radius: 20px;
    }

    .programme-right {
        min-width: 1484px;
        margin-left: 20px;
    }
.programme_title_top {
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    line-height: 22px;
    padding:20px 30px;
}
    .programme_title {
        //width: 231px;
        height: 52px;
        border-radius: 6px;
        font-size: 16px;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #333333;
    }

    .programme_action {
        //width: 220px;
        background: #7DB167;
        color: #fff;
    }
</style>
